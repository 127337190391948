import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { selectSocket } from "../app/slices/utilsSlice";
import { incrementCount, newCandidateAlert } from "../app/slices/candidateSlice";
import { useDispatch } from "react-redux";

const useSocketEvents = () => {
  const socket = useSelector(selectSocket);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const socket_newCandidate = (candidate: any) => { 
    dispatch(incrementCount());
    dispatch(newCandidateAlert({candidateInfo: candidate}));  
  };

  // Listen for events
  useEffect(() => {
    if (!socket) return;

    socket.on("new_candidate", socket_newCandidate);

    return () => {
      socket.off("new_candidate", socket_newCandidate);
    };
  }, [socket]);
};

export default useSocketEvents;
