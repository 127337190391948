import { BriefcaseMedical24Regular } from "@fluentui/react-icons";
import clsx from "clsx";
import React from "react";
import ReactTooltip from "react-tooltip";
import { preloadRouteComponent } from "../../../routes/utils/preload";
import SettingsPopup from "../SettingsPopup/SettingsPopup";

interface Props {
  showJennaLink: boolean;
  pathName: string;
  isSidebarOpen: boolean;
  handleSwitchRoute: (route: string) => void;
  route: string;
  text: string;
  dataTip: string;
  Svg: any;
  toggleMoreSettings: () => void;
  isJennaLink: boolean;
  isSettings: boolean;
  isLogout: boolean;
  isCandidates: boolean;
  newCount: number;
  userInfo: any;
  toggleJennaLink: () => void;
  functionForLogout: () => void;
}

const SideBarItem: React.FC<Props> = ({
  showJennaLink,
  pathName,
  isSidebarOpen,
  handleSwitchRoute,
  route,
  text,
  dataTip,
  Svg,
  toggleMoreSettings,
  isJennaLink,
  isSettings,
  isCandidates,
  isLogout,
  newCount,
  userInfo,
  toggleJennaLink,
  functionForLogout,
}) => {
  const isMyTeamHidden = userInfo.bot[0].payment.plan.split(" ")[0] === "Intelligent";
  const isActive =
    (pathName === route && !showJennaLink) ||
    (showJennaLink && text === "Your Jenna Link") ||
    (route === "/positions" && (pathName === "/new" || pathName.includes("edit"))) ||
    (isSettings && pathName.includes("setup") && !isSidebarOpen);

  const onClickFunction = isJennaLink
    ? toggleJennaLink
    : isLogout
    ? functionForLogout
    : isSettings
    ? toggleMoreSettings
    : () => handleSwitchRoute(route);

  route === "/positions" && pathName === "/new" && (Svg = BriefcaseMedical24Regular);

  return (
    <div
      className={clsx(
        "sidebar-nav-row",
        {
          active: isActive,
        },
        { "sidebar-nav-row-overflow": isSettings },
        { "sidebar-nav-row-collapsed": !isSidebarOpen }
      )}
      style={{ display: isMyTeamHidden && text === "My Team" ? "none" : "flex" }}
      onClick={onClickFunction}
      onMouseEnter={() => route && preloadRouteComponent(route)} // preload components
      data-for="tooltip-sidebar"
      data-tip={!isSidebarOpen ? dataTip : null}
    >
      <div
        className={clsx("sidebar-nav-icon", {
          "row-collapsed": !isSidebarOpen,
        })}
      >
        <Svg primaryFill={isActive ? "#fff" : "#c0c0c0"} />
      </div>
      <div className="sidebar-nav-text">
        <span>{text}</span>
      </div>
      {isCandidates && newCount > 0 && (
        <div
          className={clsx("sidebar-new-candidates", {
            withNumber: isSidebarOpen,
          })}
        >
          {isSidebarOpen && <span>{newCount}</span>}
        </div>
      )}
      {isSettings && <SettingsPopup handleSwitchRoute={handleSwitchRoute} />}
      <ReactTooltip className="jenna-tooltip1" id="tooltip-sidebar" place="right" type="dark" effect="solid" />
    </div>
  );
};

export default SideBarItem;
