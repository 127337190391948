import React from "react";
import Header from "../shared/Header/Header";
import JennaSpinner from "../shared/JennaSpinner/JennaSpinner";
import SideBar from "../shared/SideBar/SideBar";
import "./SuspenseSpinner.scss";

const SuspenseSpinner = () => {
  return (
    <div className="suspense">
      <JennaSpinner />
    </div>
  );
};

export default SuspenseSpinner;
