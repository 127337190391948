import {
  Briefcase24Regular,
  Grid24Regular,
  Link24Regular,
  People24Regular,
  Settings24Regular,
} from "@fluentui/react-icons";

export const sideBarItems = [
  {
    text: "Home",
    Svg: Grid24Regular,
    dataTip: "Home Page",
    route: "/",
    isJennaLink: false,
    isSettings: false,
    isCandidates: false,
    isLogout: false,
  },
  {
    text: "Positions",
    Svg: Briefcase24Regular,
    dataTip: "Positions",
    route: "/positions",
    isJennaLink: false,
    isSettings: false,
    isCandidates: false,
    isLogout: false,
  },
  {
    text: "Candidates",
    Svg: People24Regular,
    dataTip: "Candidates",
    route: "/candidates",
    isJennaLink: false,
    isSettings: false,
    isCandidates: true,
    isLogout: false,
  },
  {
    text: "Your Jenna Link",
    Svg: Link24Regular,
    dataTip: "Your Jenna Link",
    route: "",
    isJennaLink: true,
    isSettings: false,
    isCandidates: false,
    isLogout: false,
  },
  {
    text: "Settings",
    Svg: Settings24Regular,
    dataTip: "",
    route: "",
    isJennaLink: false,
    isSettings: true,
    isCandidates: false,
    isLogout: false,
  },
];

export const settingsItemsForUser = [
  {
    route: "/setup/profile",
    text: "My Profile",
  },  
  {
    route: "/member",
    text: "My Team",
  },
];

export const settingsItemsForAdmin = [
  {
    route: "/setup/profile",
    text: "My Profile",
  },
  {
    route: "/member",
    text: "My Team",
  },
  {
    route: "/setup/company",
    text: "Company",
  },
  {
    route: "/setup/integrations",
    text: "Integrations",
  },
  {
    route: "/setup/billing",
    text: "Plans & Billing",
  },
  {
    route: "/setup/privacy",
    text: "Privacy",
  },
  {
    route: "/setup/control",
    text: "Control",
  },
];
