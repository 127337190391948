import { createSlice } from "@reduxjs/toolkit";
import { fetchLogout } from "../../api/logoutAPI";
import { mpLogout } from "../../utils/resources/analytics";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    userInfo: JSON.parse(localStorage.getItem("userInfo")!),
  } as any,
  reducers: {
    "(っ◔◡◔)っ ♥ user ♥": (state, action) => {
      state.userInfo = action.payload.userInfo;
      localStorage.setItem("userInfo", JSON.stringify(action.payload.userInfo));
    },
    updateUserImage: (state, action) => {
      state.userInfo.facebook.photo = action.payload.image;
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
    updateBotSettings: (state, action) => {
      state.userInfo.bot[0] = { ...state.userInfo.bot[0], ...action.payload };
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
    "(╯ ͠° ͟ʖ ͡°)╯┻━┻ logout": (state) => {
      fetchLogout();
      mpLogout();
      state.userInfo = null;
      localStorage.removeItem("userInfo");
      localStorage.removeItem("jennaToken");
    },
  },
});

export const { updateUserImage, updateBotSettings } = userSlice.actions;
export const setUserFinish = userSlice.actions["(っ◔◡◔)っ ♥ user ♥"];
export const logout = userSlice.actions["(╯ ͠° ͟ʖ ͡°)╯┻━┻ logout"];

export const selectUser = (state: any) => state.user.userInfo;
export const selectUserBot = (state: any) => (state.user.userInfo ? state.user.userInfo?.bot[0] : null);
export const selectMaxAllowedActivePositions = (state: any) =>
  state.user.userInfo ? state.user.userInfo?.bot[0]?.plan?.maxActivePositions : null;
export const selectIsAuthenticated = (state: any) => {
  return !!state.user.userInfo;
};

export const selectIFirstTimeSetup = (state: any) => {
  return state.user.userInfo ? !!!state.user.userInfo.bot[0].name : false;
};

export const selectIsVerified = (state: any) => {
  return state.user.userInfo ? !!state.user.userInfo?.local?.isVerified : true;
};

export default userSlice.reducer;
