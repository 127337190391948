import React, { PropsWithChildren } from "react";
import "./Avatar.scss";
import randomPlaceholder from "../../../assets/userAvatars/male1.svg";

interface Props {
  url: string;
  firstname?: string;
  lastname?: string;
  isCandidate?: boolean;
}

const Avatar: React.FC<PropsWithChildren<Props>> = React.memo(({ url, firstname, lastname, isCandidate }) => {
  if (isCandidate) {
    return url ? (
      <img className="avatar-img" src={url} alt="User Avatar" />
    ) : (
      <div className="avatar-initials">
        <p>
          {firstname?.[0]?.toUpperCase()}
          {lastname?.[0]?.toUpperCase()}
        </p>
      </div>
    );
  } else {
    return <img className="avatar-img" src={url ? url : randomPlaceholder} alt="User Avatar" />;
  }
});

export default Avatar;
