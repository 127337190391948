import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "react-toastify/dist/ReactToastify.css";
import store from "./app/store";
import { Provider } from "react-redux";
import Routes from "./routes/Routes";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || "");
const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Elements stripe={stripePromise}>
          <Routes />
        </Elements>

        <ReactQueryDevtools initialIsOpen />
        <ToastContainer
          position="bottom-center"
          hideProgressBar={false}
          pauseOnHover
          newestOnTop
          limit={3}
          autoClose={6000}
        />
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
