import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "");

// const dataLayer = window.dataLayer || [];

export function mpLogin(data) {
  const { people, trialToPaid } = data;
  if (trialToPaid) {
    mpPurchase({ people, charge: trialToPaid.charge });
  }
  const planType = { "Plan Type": people["Plan Type"] };
  if (!data.registration) {
    mixpanel.identify(people.$email);
  }
  mixpanel.register(planType);
  mixpanel.track("Login");
  mixpanel.people.set(people);
}

export function mpSignup(data) {
  const { people } = data;
  mixpanel.reset();
  mixpanel.alias(people.$email);
  mixpanel.register(data.super);
  mixpanel.track("Signup");
  mixpanel.people.set(people);
}

export function mpPositionCreated(data) {
  mixpanel.track("Position Created", {
    "Position Type": data.positionType,
    "Education Level": data.educationLevel,
    "Experience in Years": data.experienceLevel,
  });
  mixpanel.people.increment("Position Created");
}

export function mpChangePlan({ people, eventData }) {
  mpPurchase({ people, charge: eventData['Total Amount'] });
  mixpanel.track("Change Plan", eventData);
  mixpanel.people.set(people);
  const incrementProp =
    eventData["Plan Change"] === "Upgrade" ? "Plan Upgrades" : "Plan Downgrades";
  mixpanel.people.increment(incrementProp);
}

export function mpCancel({ people }) {
  mixpanel.track("Cancel Plan");
  mixpanel.people.set(people);
}

export function mpLogout() {
  mixpanel.reset();
}

export function mpPurchase({ people, charge }) {
  people["Trial to Paid"] = "Yes";
  // dataLayer.push({ event: 'paying_customer' });
  mixpanel.track("Purchase");
  people["First Transaction Date"] = new Date().toISOString();
  mixpanel.people.track_charge(charge);
  mixpanel.people.increment("Lifetime Revenue", charge);
  mixpanel.people.increment("Purchase");
}
