import { createSlice } from "@reduxjs/toolkit";

export interface Phone {
    number: string;
}

export const phoneSlice = createSlice({
  name: "phone",
  initialState: {
    phone: '',
  } as any,
  reducers: {
    setPhone: (state, action) => {
        state.phone = action.payload.phone
    }
  },
});

export const { setPhone } = phoneSlice.actions;
export const selectPhone = (state: any) => state.phone.phone;
export default phoneSlice.reducer;
