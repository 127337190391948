import React from "react";
import "./JennaSideLogo.scss";
import { ReactComponent as JennaLogo } from "../../../assets/jennaLogos/Jenna_logo.svg";

const JennaSideLogo: React.FC = () => {
  return (
    <div className="jenna-side">
      <div className="jenna-side--left">
        <div>
          <JennaLogo />
        </div>
      </div>
    </div>
  );
};

export default JennaSideLogo;
