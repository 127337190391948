import clsx from "clsx";
import React from "react";
import { preloadRouteComponent } from "../../../routes/utils/preload";

interface Props {
  pathName: string;
  route: string;
  text: string;
  showJennaLink: boolean;
  handleSwitchRoute: (route: string) => void;
}

const SettingsItem: React.FC<Props> = ({ handleSwitchRoute, text, pathName, route, showJennaLink }) => {
  return (
    <div
      className={clsx({
        active: pathName === route && !showJennaLink,
      })}
      onClick={() => handleSwitchRoute(route)}
      onMouseEnter={() => preloadRouteComponent(route)} // preload components
    >
      <span>{text}</span>
    </div>
  );
};

export default SettingsItem;
