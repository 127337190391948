import axios from "../api/axios";
import { sendNotification } from "../utils/functions/sendNotification";

const useLogger = () => {
  const info = (message: string) => {
    console.log(`%c Info: ${message}`, "color: lightblue; font-weight: bold; font-size: 18px");
  };
  const warning = (message: string) => {
    console.log(`%c Warning: ${message}`, "color: orange; font-weight: bold; font-size: 18px");
  };
  const success = (message: string) => {
    console.log(`%c Success: ${message}`, "color: lightgreen; font-weight: bold; font-size: 18px");
  };
  const slack = (message: string) => {
    axios.post<any>("/maintenance/error/client", { message });
  };
  const boundary = (message: string) => {
    sendNotification("error", "Error has occurred.");
    axios.post<any>("/maintenance/error/client", { message });
  };
  const route = (message: string, location: string) => {
    console.log(
      `%c-- ${message} Routes !\n %c Path: ${location}`,
      "color: #0d1f3c; font-weight: bold; font-size: 22px",
      "color: #0d1f3c; font-weight: bold; font-size: 18px"
    );
  };

  return { info, warning, success, route, slack, boundary };
};

export default useLogger;
