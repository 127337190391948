import React, { useState } from "react";
import { useNonInitialEffect } from "../../../hooks/useNonInitialRender";
import { simplify } from "../../../utils/functions/utilFunctionsV3.0";
import "./styles.scss";
import { Animations, animations } from "./theme";

interface Props {
  children: any;
  className?: string;
  durationOut?: number;
  animation?: keyof Animations;
  animationIn?: keyof Animations;
  animationOut?: keyof Animations;
  style?: any;
}

const JennaTransition: React.FC<Props> = ({
  animation: animationBaseName,
  animationIn = `${animationBaseName}In`,
  animationOut = `${animationBaseName}Out`,
  children,
  className,
  durationOut = 200,
  style,
}) => {
  const [skipAnim, setSkipAnim] = useState(false);

  const [animation, setAnimation] = useState("in");
  const [displayContent, setDisplayContent] = useState(children);

  useNonInitialEffect(() => {
    // Prevent rerendering when it gets same children
    // REMOVED TO FIX LIKING ON CandidatesView - may cause problems down the line
    // if (JSON.stringify(simplify(displayContent)) === JSON.stringify(simplify(children))) return;
    // Prevent rerendering for CandidateView.tsx
    skipAnim && setSkipAnim(false);

    if (
      simplify(displayContent)?.[0].props?.loadedCandidate?._id &&
      JSON.stringify(simplify(displayContent)?.[0].props?.loadedCandidate?._id) ===
        JSON.stringify(simplify(children)?.[0].props?.loadedCandidate?._id)
    )
      setSkipAnim(true);

    setAnimation("out");

    // Fix a bug where animation would sometimes be stuck
    // on out and no content would be displayed
    let timeout = setTimeout(forceDisplayContent, 200);
    return () => clearTimeout(timeout);
  }, [children]);

  const showDisplayContent = () => {
    if (animation === "out") {
      setAnimation("in");
      setDisplayContent(children);
    }
  };

  const forceDisplayContent = () => {
    setAnimation("in");
    setDisplayContent(children);
  };

  const styles = skipAnim
    ? { ...style }
    : {
        transition: !className && `opacity ${durationOut}ms ease-out`,
        opacity: !className && animation === "out" ? 0 : 1,
        animationDuration: durationOut + "ms",
        ...style,
      };

  switch (animation) {
    case "in":
      //@ts-ignore
      styles.animation = skipAnim ? "none" : animations[animationIn] || animationIn;
      break;
    case "out":
      //@ts-ignore
      styles.animation = skipAnim ? "none" : animations[animationOut] || animationOut;
      break;
  }

  const baseClassName = className || "animate-on-change";

  return (
    <div
      onTransitionEnd={showDisplayContent}
      onAnimationEnd={showDisplayContent}
      className={`${baseClassName} ${baseClassName}-${animation}`}
      style={styles}
    >
      {displayContent}
    </div>
  );
};

export default JennaTransition;
