import React, { useState } from "react";
import "../../modals/styles/SharedStyles.scss";
import "./ImageUploadSidebar.scss";
import Modal from "react-modal";
import { avatars } from "./avatars";
import axios from "../../../api/axios";
import { useDispatch } from "react-redux";
import { updateUserImage } from "../../../app/slices/userSlice";

Modal.setAppElement("#root");

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  isLoading: any;
}

const ImageUploadSidebar: React.FC<Props> = ({ isOpen, toggleOpen, isLoading }) => {
  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    e.preventDefault();
    isLoading(true);
    toggleOpen();

    if (e.target.files.length) {
      const formData = new FormData();
      formData.append("img", e.target.files[0]);

      axios.post<any>("uploadUserImage", formData).then((res) => {
        dispatch(updateUserImage({ image: res.data.img }));
        isLoading(false);
      });
    }
  };

  const handleUploadAvatar = (avatar: string) => {
    isLoading(true);
    toggleOpen();
    axios.post<any>("uploadUserImageAvatar", { img: `${avatar}.svg` }).then((res) => {
      dispatch(updateUserImage({ image: res.data.img }));
      isLoading(false);
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleOpen}
      shouldCloseOnOverlayClick
      className="jenna-modal-content imageUpload-styles"
      overlayClassName="jenna-modal-overlay"
      contentLabel="Upload Avatar Modal"
    >
      <div className="top-avatars">
        {avatars.map((avatar) => (
          <img
            key={avatar}
            onClick={() => handleUploadAvatar(avatar)}
            src={require(`../../../assets/userAvatars/${avatar}.svg`).default}
            alt="User avatar"
          />
        ))}
      </div>
      <div className="bottom-upload">
        <span>
          Upload <strong>PNG</strong> or <strong>JPG</strong> not more than <strong>2MB</strong> in size.
        </span>
        <br />
        <label htmlFor="image">
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={handleChange}
            style={{ display: "none" }}
          />
          <div className="upload-button">Upload</div>
        </label>
      </div>
    </Modal>
  );
};

export default ImageUploadSidebar;
