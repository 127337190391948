import { useDispatch, useSelector } from "react-redux";
import { fetchNoOfNew } from "../api/candidatesAPI";
import { updateCount } from "../app/slices/candidateSlice";
import { selectIFirstTimeSetup, selectIsAuthenticated, selectIsVerified } from "../app/slices/userSlice";

const useGetNoOfNew = () => {
  const dispatch = useDispatch();
  const isFirstTime = useSelector(selectIFirstTimeSetup);
  const isVerified = useSelector(selectIsVerified);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const token = localStorage.getItem("jennaToken");

  if ((!isFirstTime || isVerified) && isAuthenticated && token) {
    fetchNoOfNew().then((res) => {
      dispatch(updateCount({ count: res.data.newCount }));
    });
  } else {
    dispatch(updateCount({ count: 0 }));
  }
};

export default useGetNoOfNew;
