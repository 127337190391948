import { matchPath } from "react-router-dom";
import { allRoutes } from "../config/routesConfig";

const findComponentForRoute = (path: string) => {
  const matchingRoute = allRoutes.find((route) =>
    matchPath(path, {
      path: route.path,
      exact: route.exact,
    })
  );

  return matchingRoute ? matchingRoute.component : null;
};

export const preloadRouteComponent = (path: string) => {
  const component = findComponentForRoute(path);

  // @ts-ignore
  if (component && component.preload) {
    // @ts-ignore
    component.preload();
  }
};
