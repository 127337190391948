import { ComponentType, FC } from "react";
import { ErrorBoundaryContext } from "./CTX";

export function withErrorBoundary<Props = Record<string, unknown>>(WrappedComponent: ComponentType<Props>): FC<Props> {
  return (props: Props) => (
    <ErrorBoundaryContext>
      <WrappedComponent key="WrappedComponent" {...props} />
    </ErrorBoundaryContext>
  );
}
