export const avatars = [
  "male1",
  "male2",
  "male3",
  "male4",
  "male5",
  "female1",
  "female2",
  "female3",
  "female4",
  "female5",
];
