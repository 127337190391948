import clsx from "clsx";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUserBot } from "../../../app/slices/userSlice";
import { selectIsSidebarOpen } from "../../../app/slices/utilsSlice";
import "./YourJennaLink.scss";

interface State {
  isCopied: boolean;
}

interface Props {
  slideIn: string | boolean;
  slideOut: string | boolean;
}

const YourJennaLink: React.FC<Props> = ({ slideIn, slideOut }) => {
  const [state, setState] = useState<State>({
    isCopied: false,
  });
  const bot = useSelector(selectUserBot);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_WEBCHAT_URL}/${bot?._id}`);
    setState({ ...state, isCopied: true });
    setTimeout(() => {
      setState({ ...state, isCopied: false });
    }, 2000);
  };

  const webchatLink = `${process.env.REACT_APP_WEBCHAT_URL}/${bot?._id}`;
  return (
    <div
      className={clsx(
        "jenna-link",
        { "sidebar-open": isSidebarOpen },
        {
          "anim-slideIn": slideIn,
        },
        { "anim-slideOut": slideOut }
      )}
    >
      {/* <div className="drawer-wide-circle">
        <div className="drawer-narrow-circle"></div>
      </div> */}
      <p className="drawer-copy-title">Copy the link to share</p>
      <div className="drawer-jenna-link">
        {state.isCopied ? (
          <div className="drawer-link-copied">Link copied!</div>
        ) : (
          <div className="drawer-link-outer">
            <span onClick={handleCopyToClipboard}>
              <input className="jenna-link-input" type="text" value={webchatLink} readOnly />
              <span className="drawer-copy-link-button">
                <div>
                  <img src={require("../../../assets/copyLink.png").default} alt="Copy jenna link" />
                </div>
              </span>
            </span>
          </div>
        )}
      </div>
      <p className="how-to-use">Tip on how to use the link</p>
      <div className="how-to-use-container">
        <div className="how-to-step">
          <div className="how-to-step-img img-1">
            <div>1</div>
          </div>
          <div className="how-to-step-text">
            Post your Jenna link to relevant Facebook and LinkedIn groups. Add some position marketing text and see
            those applications coming
          </div>
        </div>
        <div className="dashed-line"></div>
        <div className="how-to-step">
          <div className="how-to-step-img img-2">
            <div>2</div>
          </div>
          <div className="how-to-step-text">
            Create Facebook Click-To-Messenger ads with relevant targeting{" "}
            <a
              href="https://www.facebook.com/business/news/start-conversations-with-your-customers-using-click-to-messenger-ads"
              target="_blank"
              rel="noopener noreferrer"
            >
              (Click for info*)
            </a>{" "}
            or Create a LinkedIn job ad and place your Jenna link in link section{" "}
            <a
              href="https://www.facebook.com/business/news/start-conversations-with-your-customers-using-click-to-messenger-ads"
              target="_blank"
              rel="noopener noreferrer"
            >
              (Click for info**)
            </a>
          </div>
        </div>
        <div className="dashed-line"></div>
        <div className="how-to-step">
          <div className="how-to-step-img img-3">
            <div>3</div>
          </div>
          <div className="how-to-step-text">
            You can use your Jenna link on your website, display ads or anywhere else you would like to onboard
            candidates from
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourJennaLink;
