import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectUser } from "../app/slices/userSlice";
import { sendNotification } from "../utils/functions/sendNotification";

const adminOnly = ["/setup/integrations", "/setup/company", "/setup/billing", "/setup/privacy", "/setup/control"];
const planCanceled = ["/new"];

const useHandlePremissions = () => {
  const history = useHistory();
  const userInfo = useSelector(selectUser);

  if (adminOnly.includes(history.location.pathname) && userInfo?.local?.isMember) {
    sendNotification("info", "Premission denied.");
    return { shouldRedirect: true, path: "/positions" };
  }

  if (
    (planCanceled.includes(history.location.pathname) || history.location.pathname.includes("/edit")) &&
    userInfo?.bot[0]?.payment?.canceled
  ) {
    sendNotification("info", "Plan is canceled.");
    return { shouldRedirect: true, path: "/positions" };
  }

  return { shouldRedirect: false, path: "/" };
};

export default useHandlePremissions;
