import { AxiosError } from "axios";
import axios from "./axios";
import { formatDate } from "../utils/functions/utilFunctions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { startCall } from "../app/slices/candidateSlice";
import { useDispatch } from "react-redux";
import { sendNotification } from "../utils/functions/sendNotification";
import { CVFormat } from "../types/candidate.types";

export const useGetCandidatesData = (filtersData: any, paramPos: string | null) => {

  return useQuery(
    "candidates",
    async () => {
      const { data } = await axios.post<any>("/candidates/filter", {
        candidateName: filtersData.searchValue,
        dateOrder: filtersData.dateOrder,
        datefrom: formatDate(filtersData.time).datefrom,
        dateto: formatDate(filtersData.time).dateto,
        filteredAndRejected: filtersData.showFilteredAndRejected,
        matchOrder: filtersData.matchOrder,
        nameOrder: filtersData.nameOrder,
        positionId: paramPos || filtersData.position,
        saved: filtersData.showSaved,
        status: filtersData.status,
        page: filtersData.currentPage,
        amount: filtersData.amount,
      });
      return data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useGetTotalNumbers = (filterPosition?: string | null, paramPos?: string | null) => {
  return useQuery(
    "candidateCount",
    async () => {
      const { data } = await axios.post<any>("/candidates/totalnumber", {
        positionId: paramPos || filterPosition
      });
      return data;
    }, {
    staleTime: Infinity,
  }
  );
};

export const fetchToggleStarred = async (id?: string) => {
  const res = await axios.post<any>("/api/candidates/togglesaved", {
    candidateid: id,
  });
  return res.data;
};

export const likeCandidate = async (id: string | number, { status, approvedBy, rejectedBy }: { status: string, approvedBy: string[], rejectedBy: string[] }) => {
  const res = await axios.patch(`/api/candidates/${id}`, {
    status,
    approvedBy,
    rejectedBy
  });
  return res.data;
};

export const fetchNextStep = async (data: any) => {
  const res = await axios.post<any>(`/api/candidates/nextstep`, {
    id: data.candidateId,
    text: data.text,
    subject: data.subject,
    type: data.type
  });
  return res.data;
};

export const fetchNextStepNoRes = async (data: any) => {
  const res = await axios.post<any>(`/api/candidates/nextstep`, {
    id: data.candidateId,
    type: data.type
  });
  return res.data;
};

export const fetchTwilioSms = async (data: any) => {
  const res = await axios.post<any>(`/api/twilio/sms`, {
    To: data.to,
    msg: data.text,
  });
  return res.data;
};

export const fetchReject = async (data: any) => {
  const res = await axios.post<any>(`/api/candidates/reject`, {
    id: data.candidateId,
    text: data.text,
    subject: data.subject,
    type: data.type
  });
  return res.data;
};

export const fetchRejectNoRes = async (data: any) => {
  const res = await axios.post<any>(`/api/candidates/reject`, {
    id: data.candidateId,
    type: data.type
  });
  return res.data;
};

export const fetchRejectSms = async (data: any) => {
  const res = await axios.post<any>(`/api/candidates/reject`, {
    id: data.candidateId,
  });
  return res.data;
};

export const fetchUpdateNotes = async (id: string | number, comment: string) => {
  const res = await axios.post<any>("/api/candidates/comment", {
    cId: id,
    comment,
  });
  return res.data;
};

export const fetchNoOfNew = async () => {
  const res = await axios.post<any>("/api/candidates/noOfNew");
  return res;
};

export const fetchCSVData = async (positionId: string) => {
  const res = await axios.post<any>("/candidates/filter", {
    candidateName: "",
    dateOrder: "",
    datefrom: formatDate("All Time").datefrom,
    dateto: formatDate("All Time").dateto,
    filteredAndRejected: false,
    matchOrder: null,
    nameOrder: null,
    page: 1,
    positionId: positionId,
    saved: false,
    status: "all",
  });
  return res.data;
};

export const useDeleteCandidateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: any) =>
      axios.delete(`/api/candidates/`, {
        params: {
          candidate: params.candId,
          position: params.posId,
        },
      }),
    {
      onSuccess: () => {
        sendNotification("success", "Candidate deleted.");
        queryClient.invalidateQueries("positions");
        queryClient.invalidateQueries("mainPage");
        queryClient.invalidateQueries("candidates");
      },
      onError: () => {
        sendNotification("error", "Deletion failed.");
      },
    }
  );
};

export const useRescoreCandidateMutation = () => {
  return useMutation((id: string) =>
    axios.post<any>(`/candidate/score/${id}`, null, { baseURL: process.env.REACT_APP_ADMIN })
  );
};

export const useCallCandidate = () => {
  const dispatch = useDispatch();
  const fetchTwilio = async (candidate: any) => {
    const res = await axios.get<any>("/api/twilio/token");
    dispatch(
      startCall({
        candidateInfo: {
          profile_pic: candidate.profile_pic,
          first_name: candidate.first_name,
          last_name: candidate.last_name,
          phone_info: candidate.phoneInfo || null,
        },
      })
    );
    return res.data.token;
  };
  return { fetchTwilio };
};

export const fetchTwilioNumber = async () => {
  const res = await axios.get<any>("/api/twilio/token");
  return res.data.token;
};

export const fetchViewed = (id: string) => {
  axios.patch(`/api/candidates/${id}`, { status: "viewed" }).catch(() => { });
};

export const fetchUploadCV = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  const res = await axios.post<any>(`/uploadCv`, formData, {
    baseURL: process.env.REACT_APP_CHAT_API_URL,
    headers: {
      "content-type": "multipart/form-data",
    },
  });

  return res.data;
};

export const fetchResumeUpdate = async (id: string, field: string, value: string | CVFormat) => {
  await axios
    .patch(`/api/candidates/${id}`, { [field]: value })
    .then((res: any) => !res.data.error && sendNotification("success", res.data.message))
    .catch((e: AxiosError<any>) => sendNotification("error", e?.response?.data?.message));
};
