import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectIFirstTimeSetup, selectIsVerified } from "../app/slices/userSlice";
import { sendNotification } from "../utils/functions/sendNotification";
import useLogger from "./useLogger";

const allowedRoutes = ["/signin", "/register", "/setup/company"];
const partialRoutes = ["/verify", "/becomeMember"];

const useHandleFirstTime = () => {
  const history = useHistory();
  const logger = useLogger();
  const currentRoute = history.location.pathname;

  const isFirstTime = useSelector(selectIFirstTimeSetup);
  const isVerified = useSelector(selectIsVerified);

  if (isFirstTime || !isVerified) {
    logger.route("First Time / Unverified", currentRoute);
    const found = partialRoutes.find((val) => currentRoute.includes(val));

    if (!allowedRoutes.includes(currentRoute) && !found) {
      sendNotification("info", "Finish setup first.");
      return setTimeout(() => history.push("/setup/company"), 500);
    }
  }
  logger.route("Normal", currentRoute);
};

export default useHandleFirstTime;
