import { ReactLazyPreload } from "../utils/ReactLazyPreload";

import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";

const MainPage = ReactLazyPreload(() => import("../../Pages/Main/Main"));
const SignIn = ReactLazyPreload(() => import("../../Pages/SignIn/SignIn"));
const Register = ReactLazyPreload(() => import("../../Pages/Register/Register"));
const ForgotPassword = ReactLazyPreload(() => import("../../Pages/ForgotPassword/ForgotPassword"));
const ResetPassword = ReactLazyPreload(() => import("../../Pages/ResetPassword/ResetPassword"));
const Positions = ReactLazyPreload(() => import("../../Pages/Positions/Positions"));
const Candidates = ReactLazyPreload(() => import("../../Pages/Candidates/Candidates"));
const ViewProfile = ReactLazyPreload(() => import("../../Pages/ViewProfile/ViewProfile"));
const Company = ReactLazyPreload(() => import("../../Pages/Company/Company"));
const MyTeam = ReactLazyPreload(() => import("../../Pages/MyTeam/MyTeam"));
const Integrations = ReactLazyPreload(() => import("../../Pages/Integrations/Integrations"));
const Billing = ReactLazyPreload(() => import("../../Pages/Billing/Billing"));
const NewPosition = ReactLazyPreload(() => import("../../Pages/NewPosition/NewPosition"));
const EditPosition = ReactLazyPreload(() => import("../../Pages/EditPosition/EditPosition"));
const BecomeMember = ReactLazyPreload(() => import("../../Pages/BecomeMember/BecomeMember"));
const VerifyMail = ReactLazyPreload(() => import("../../Pages/VerifyMail/VerifyMail"));
const Privacy = ReactLazyPreload(() => import("../../Pages/Privacy/Privacy"));
const Control = ReactLazyPreload(() => import("../../Pages/Control/Control"));
const NPSLanding = ReactLazyPreload(() => import("../../Pages/NPSLanding/NPSLanding"));
const Unsub = ReactLazyPreload(() => import("../../Pages/Unsub/Unsub"));
const UnsubCand = ReactLazyPreload(() => import("../../Pages/UnsubCand/UnsubCand"));

const Page404 = ReactLazyPreload(() => import("../../Pages/Page404/Page404"));

export const allRoutes = [
  { path: "/register", exact: true, component: Register, RouteComponent: PublicRoute },
  { path: "/signin", exact: true, component: SignIn, RouteComponent: PublicRoute },
  { path: "/forgot", exact: true, component: ForgotPassword, RouteComponent: PublicRoute },
  { path: "/reset/:resetToken", exact: true, component: ResetPassword, RouteComponent: PublicRoute },
  { path: "/becomeMember/:token?/:email?", exact: true, component: BecomeMember, RouteComponent: PublicRoute },
  { path: "/verifyMail/:token?", exact: true, component: VerifyMail, RouteComponent: PublicRoute },
  { path: "/nps/:candidate?/:bot?/:score?", exact: true, component: NPSLanding, RouteComponent: PublicRoute },
  { path: "/unsub", exact: true, component: Unsub, RouteComponent: PublicRoute },
  { path: "/unsubCand/:email?", exact: true, component: UnsubCand, RouteComponent: PublicRoute },

  { path: "/positions", exact: true, component: Positions, RouteComponent: PrivateRoute },
  { path: "/candidates/:position?/:candidate?", exact: true, component: Candidates, RouteComponent: PrivateRoute },
  { path: "/member", exact: true, component: MyTeam, RouteComponent: PrivateRoute },
  { path: "/new", exact: true, component: NewPosition, RouteComponent: PrivateRoute },
  { path: "/edit/:id", exact: true, component: EditPosition, RouteComponent: PrivateRoute },

  { path: "/setup/profile", exact: true, component: ViewProfile, RouteComponent: PrivateRoute },
  { path: "/setup/integrations", exact: true, component: Integrations, RouteComponent: PrivateRoute },
  { path: "/setup/control", exact: true, component: Control, RouteComponent: PrivateRoute },
  { path: "/setup/billing", exact: true, component: Billing, RouteComponent: PrivateRoute },
  { path: "/setup/privacy", exact: true, component: Privacy, RouteComponent: PrivateRoute },
  { path: "/setup/company", exact: true, component: Company, RouteComponent: PrivateRoute },

  { path: "/", exact: true, component: MainPage, RouteComponent: PrivateRoute },

  { path: "*", exact: false, component: Page404, RouteComponent: PublicRoute },
];
