import { ComponentLifecycle, useCallback, useContext } from "react";
import { errorBoundaryContext } from "./CTX";

type UseErrorBoundaryReturn = [error: boolean, resetError: () => void];
type ComponentDidCatch = ComponentLifecycle<{}, {}>["componentDidCatch"];

export function useErrorBoundary(componentDidCatch?: ComponentDidCatch): UseErrorBoundaryReturn {
  const ctx = useContext(errorBoundaryContext);
  ctx.componentDidCatch.current = componentDidCatch;
  const resetError = useCallback(() => {
    ctx.setError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [ctx.error, resetError];
}
