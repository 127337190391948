import { useState, FC, createContext, MutableRefObject, useMemo, useRef, ComponentLifecycle } from "react";
import { ErrorBoundary } from "./ErrorBoundary";

const noop = () => false;
type ComponentDidCatch = ComponentLifecycle<{}, {}>["componentDidCatch"];

interface ErrorBoundaryCtx {
  componentDidCatch: MutableRefObject<ComponentDidCatch>;
  error: boolean;
  setError: (error: boolean) => void;
}

export const errorBoundaryContext = createContext<ErrorBoundaryCtx>({
  componentDidCatch: { current: undefined },
  error: false,
  setError: noop,
});

export const ErrorBoundaryContext: FC = ({ children }) => {
  const [error, setError] = useState(false);
  const componentDidCatch = useRef<ComponentDidCatch>();
  const ctx = useMemo(
    () => ({
      componentDidCatch,
      error,
      setError,
    }),
    [error]
  );
  return (
    <errorBoundaryContext.Provider value={ctx}>
      <ErrorBoundary
        error={error}
        onError={(...args) => {
          setError(true);
          componentDidCatch.current?.(...args);
        }}
      >
        {children}
      </ErrorBoundary>
    </errorBoundaryContext.Provider>
  );
};
