import React from "react";
import "./NewCandidateNotification.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";

interface Props {
  history: any;
  firstName: string;
  lastName: string;
  positionName: string;
  id: string;
}

const NewCandidateNotification: React.FC<Props> = ({ history, firstName, lastName, positionName, id }) => {
  const redirectToCandidateApplication = () => {
    history.push(`/candidates?candidate=${id}`);
  };

  return (
    <div className="content">
      <div className="notification-header">New Application</div>
      <div className="message">{`${firstName} ${lastName} has just applied for the position ${positionName}.`}</div>
      <div className="application" onClick={redirectToCandidateApplication}>
        <FontAwesomeIcon icon={faArrowAltCircleRight} size="lg" />
        <span style={{ marginLeft: "10px" }}>View Application</span>
      </div>
    </div>
  );
};

export default NewCandidateNotification;
