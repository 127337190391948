import { useSelector } from "react-redux";
import { selectUser } from "../../../app/slices/userSlice";
import { preloadRouteComponent } from "../../../routes/utils/preload";
import { settingsItemsForAdmin, settingsItemsForUser } from "../SideBar/sideBarItemData";
import "./SettingsPopup.scss";

interface Props {
  handleSwitchRoute: (route: string) => void;
}

const SettingsPopup: React.FC<Props> = ({ handleSwitchRoute }) => {
  const userInfo = useSelector(selectUser);
  const settingsItems = !userInfo?.local?.isMember ? settingsItemsForAdmin : settingsItemsForUser;

  return (
    <div className="settings-popup">
      {settingsItems.map((item) => (
        <span
          key={item.route}
          onMouseEnter={() => preloadRouteComponent(item.route)} // preload components
          onClick={() => handleSwitchRoute(item.route)}
        >
          {item.text}
        </span>
      ))}
    </div>
  );
};

export default SettingsPopup;
