import clsx from "clsx";
import "./JennaButton.scss";
import "../../../utils/scss/ripple.scss";
interface Props {
  type?: "primary" | "secondary";
  action?: "primaryCta" | "cancel" | "inactive";
  onClick: any;
  text: string;
  size?: "small" | "medium" | "large";
  className?: string;
  width?: number;
  color?: string;
  Icon?: SVGElement;
  isLoading?: boolean;
  hidden?: boolean;
  disabled?: boolean;
}

const JennaButton: React.FC<Props> = ({
  text,
  onClick,
  type = "primary",
  Icon,
  color,
  size = "medium",
  width,
  action = "primaryCta",
  className,
  isLoading = false,
  hidden = false,
  disabled = false,
}) => {
  const potentialStyles = {
    width: width + "px",
    display: hidden ? "none" : "flex",
    cursor: disabled ? "default" : "pointer",
    color,
  };

  const Spinner = <div className="loader"></div>;
  const clickFunc = !disabled && !isLoading ? onClick : () => {};
  action = disabled ? "inactive" : action;
  const ripple = disabled ? "" : "ripple";

  return (
    <div
      className={clsx("jenna-button", ripple, type, action, className, `size-${size}`)}
      onClick={clickFunc}
      style={potentialStyles}
    >
      {isLoading ? Spinner : text}
    </div>
  );
};

export default JennaButton;
