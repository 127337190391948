import React from "react";
import "./ErrorBoundary.scss";
import { ReactComponent as BrokenHeart } from "./broken-heart.svg";
import { withErrorBoundary } from "./withErrorBoundary";
import { useErrorBoundary } from "./useErrorBoundary";
import useLogger from "../../hooks/useLogger";
import { useHistory } from "react-router-dom";
import JennaTransition from "../shared/JennaTransition/JennaTransition";

interface Props {
  children: React.ReactNode;
}

const GlobalErrorBoundary: React.FC<Props> = withErrorBoundary(({ children }) => {
  const logger = useLogger();
  const history = useHistory();
  const [error, resetError] = useErrorBoundary((error) => logger.boundary(error.stack || error.message));

  history.listen(() => {
    // Reset state on route change
    if (!error) return;
    resetError();
  });

  if (error) {
    return (
      <JennaTransition>
        <div className="error-boundary">
          <div className="error-right">
            <BrokenHeart />
          </div>
        </div>
      </JennaTransition>
    );
  }
  return <>{children}</>;
});

export default GlobalErrorBoundary;
