export const easings = {
  linear: "linear",
  easeInSine: "cubic-bezier(0.47, 0, 0.745, 0.715)",
  easeOutSine: "cubic-bezier(0.39, 0.575, 0.565, 1)",
  easeInOutSine: "cubic-bezier(0.445, 0.05, 0.55, 0.95)",
  easeInQuad: "cubic-bezier(0.55, 0.085, 0.68, 0.53)",
  easeOutQuad: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
  easeInOutQuad: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
  easeInCubic: "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
  easeOutCubic: "cubic-bezier(0.215, 0.61, 0.355, 1)",
  easeInOutCubic: "cubic-bezier(0.645, 0.045, 0.355, 1)",
  easeInQuart: "cubic-bezier(0.895, 0.03, 0.685, 0.22)",
  easeOutQuart: "cubic-bezier(0.165, 0.84, 0.44, 1)",
  easeInOutQuart: "cubic-bezier(0.77, 0, 0.175, 1)",
  easeInQuint: "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
  easeOutQuint: "cubic-bezier(0.23, 1, 0.32, 1)",
  easeInOutQuint: "cubic-bezier(0.86, 0, 0.07, 1)",
  easeInExpo: "cubic-bezier(0.95, 0.05, 0.795, 0.035)",
  easeOutExpo: "cubic-bezier(0.19, 1, 0.22, 1)",
  easeInOutExpo: "cubic-bezier(1, 0, 0, 1)",
  easeInBack: "cubic-bezier(0.6, -0.28, 0.735, 0.045)",
  easeOutBack: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
  easeInOutBack: "cubic-bezier(0.68, -0.55, 0.265, 1.55)",
};

export const animations: Animations = {
  fadeIn: `fade-in 500ms ease-out forwards`,
  fadeOut: `fade-out 400ms ease-out forwards`,
  fadeInUp: `fade-in-up 800ms ${easings.easeOutExpo} forwards`,
  popIn: `pop-in 500ms ${easings.easeOutExpo} forwards`,
  popOut: `pop-out 400ms ${easings.easeOutBack} forwards`,
  bounceIn: `pop-in 300ms ${easings.easeOutBack} forwards`,
  bounceOut: `pop-out 300ms ${easings.easeInBack} forwards`,
  slideIn: `slide-in 500ms ${easings.easeInOutBack} forwards`,
  slideOut: `slide-out 350ms ${easings.easeOutBack} forwards`,
  slideSidewaysRightIn: `slide-sideways-r-in 500ms ${easings.easeInOutBack} forwards`,
  slideSidewaysRightOut: `slide-sideways-r-out 700ms ${easings.easeOutExpo} forwards`,
  slideSidewaysLeftIn: `slide-sideways-l-in 500ms ${easings.easeInOutBack} forwards`,
  slideSidewaysLeftOut: `slide-sideways-l-out 700ms ${easings.easeOutExpo} forwards`,
};

export interface Animations {
  fadeIn: string;
  fadeOut: string;
  fadeInUp: string;
  popIn: string;
  popOut: string;
  bounceIn: string;
  bounceOut: string;
  slideIn: string;
  slideOut: string;
  slideSidewaysRightIn: string;
  slideSidewaysRightOut: string;
  slideSidewaysLeftIn: string;
  slideSidewaysLeftOut: string;
}
