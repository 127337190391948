import { PropsWithChildren } from "react";
import { Route, Redirect } from "react-router-dom";
import { RenderRawComponent } from "../utils/RenderComponent";

interface Props {
  component: any;
  restricted?: boolean;
  exact?: boolean;
  path: string;
}

const PublicRoute: React.FC<PropsWithChildren<Props>> = ({ component: Component, restricted, ...rest }) => {
  // Used as regular Route with ability to add restricted

  return (
    <Route
      {...rest}
      render={(props) =>
        restricted ? <Redirect to="/" /> : <RenderRawComponent component={Component} props={props} />
      }
    />
  );
};

export default PublicRoute;
