import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { selectUser } from "../app/slices/userSlice";
import { addSocket, selectSocket } from "../app/slices/utilsSlice"; 

const API_URL = process.env.REACT_APP_API_URL || "";
const JWT_HEADER = { Authorization: "Bearer " + localStorage.getItem("jennaToken") };

const useSocket = () => { 
  const userId = useSelector(selectUser)?._id;
  const socket = useSelector(selectSocket);
  const dispatch = useDispatch();
  
  const joinRoom = () => {
    if (!socket) return;

    socket.emit("join_room", { userId }); 
  };

  const initSocket = () => {
    const socket = io(API_URL, { extraHeaders: JWT_HEADER });

    dispatch(addSocket(socket));
  };

  return { initSocket, joinRoom, socket };
};

export default useSocket;
