import React from "react";
import "../styles/SharedStyles.scss";
import "./ConfirmationModal.scss";
import Modal from "react-modal";
import JennaButton from "../../shared/JennaButton/JennaButton";

Modal.setAppElement("#root");

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  action: any;
  isLoading?: boolean;
  title?: string;
  subtitle?: string;
  color?: "cancel" | "primaryCta";
  firstBtnTxt?: string;
  secondBtnTxt?: string;
}

const ConfirmationModal: React.FC<Props> = ({
  isOpen,
  toggleOpen,
  action,
  title,
  subtitle,
  isLoading = false,
  color = "cancel",
  firstBtnTxt = "Delete",
  secondBtnTxt = "Cancel",
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleOpen}
      shouldCloseOnOverlayClick
      className="jenna-modal-content delete-modal"
      overlayClassName="jenna-modal-overlay"
      contentLabel="Confirm Deletion Modal"
    >
      <span className="modal-title">{title || "Delete candidate"}</span>
      <span className="modal-subtitle">{subtitle || "Delete all data about this candidate forever!"}</span>

      <JennaButton
        className="cta"
        action={color}
        type="primary"
        text={firstBtnTxt}
        width={150}
        onClick={action}
        isLoading={isLoading}
      />
      <JennaButton
        className="cta cta-cancel"
        action="inactive"
        type="secondary"
        text={secondBtnTxt}
        width={150}
        onClick={toggleOpen}
      />
    </Modal>
  );
};

export default ConfirmationModal;
