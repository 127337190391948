import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ConferenceRoom24Regular, Navigation24Filled, SignOut24Regular } from "@fluentui/react-icons";
import clsx from "clsx";
import "./SideBar.scss";

import YourJennaLink from "../YourJennaLink/YourJennaLink";
import Avatar from "../Avatar/Avatar";
import { ReactComponent as Hamburger } from "../../../assets/hamburger.svg";
import {
  selectIsSidebarOpen,
  selectPageTouched,
  toggleSidebar,
  togglePageTouched,
} from "../../../app/slices/utilsSlice";
import { logout, selectIFirstTimeSetup, selectIsVerified, selectUser } from "../../../app/slices/userSlice";
import ImageUploadSidebar from "../../ImageUploads/ImageUploadSidebar/ImageUploadSidebar";
import { selectNewCandidates, newCandidateAlert, selectNewCandidateInfo } from "../../../app/slices/candidateSlice";
import SideBarItem from "./SideBarItem";
import { settingsItemsForAdmin, settingsItemsForUser, sideBarItems } from "./sideBarItemData";
import SettingsItem from "./SettingsItem";
import ConfirmationModal from "../../modals/ConfirmationModal/ConfirmationModal";
import NewCandidateNotification from "../../Notification/NewCandidateNotification";
import { sendNotification } from "../../../utils/functions/sendNotification";
import useCacheState from "../../../hooks/useCacheState";

Modal.setAppElement("#root");
interface State {
  showJennaLink: boolean;
  openModal: boolean;
  openModal2: boolean;
  openModal3: boolean;
  isSettingsOpen: boolean;
}

const SideBar: React.FC = () => {
  const [state, setState] = useState<State>({
    showJennaLink: false,
    openModal: false,
    openModal2: false,
    openModal3: false,
    isSettingsOpen: false,
  });
  const [nextRoute, setNextRoute] = useState<string>("");
  const [isUploadingImg, setIsUploadingImg] = useState<boolean>(false);

  const userInfo = useSelector(selectUser);
  const isFirstTime = useSelector(selectIFirstTimeSetup);
  const isVerified = useSelector(selectIsVerified);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const newCount = useSelector(selectNewCandidates);
  const pageTouched = useSelector(selectPageTouched);
  const newCandidate = useSelector(selectNewCandidateInfo);
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const cachedState = useCacheState();

  const settingsItems = !userInfo?.local?.isMember ? settingsItemsForAdmin : settingsItemsForUser;

  useEffect(() => {
    if (!newCandidate) return;
 
    queryClient.invalidateQueries("candidates");
    queryClient.invalidateQueries("candidateCount");

    toast(
      <NewCandidateNotification
        history={history}
        firstName={newCandidate.first_name}
        lastName={newCandidate.last_name}
        positionName={newCandidate.position.name}
        id={newCandidate._id}
      />,
      {
        type: "info",
        hideProgressBar: true,
        position: "bottom-center",
        bodyClassName: "toast-new-candidate",
      }
    );

    dispatch(newCandidateAlert({ candidateInfo: null }));
  }, [newCandidate]);

  const toggleJennaLink = () => {
    if (isFirstTime) return sendNotification("info", "Finish setup first.");
    if (!isVerified) return sendNotification("info", "Verify your email first.");

    setState({ ...state, showJennaLink: !state.showJennaLink });
  };

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
    setState({ ...state, isSettingsOpen: false });
  };

  const toggleMoreSettings = () => {
    isSidebarOpen && setState({ ...state, isSettingsOpen: !state.isSettingsOpen });
  };

  const handleSwitchRoute = (route: string) => {
    if (isFirstTime) return sendNotification("info", "Finish setup first.");
    if (!isVerified) return sendNotification("info", "Verify your email first.");

    if (pageTouched || history.location.pathname === "/new" || history.location.pathname.includes("edit")) {
      setNextRoute(route);
      handleToggleModal();

      return;
    }
    setState({ ...state, showJennaLink: false });
    history.push(route);
  };

  const handleConfirmLeave = () => {
    handleToggleModal();
    dispatch(togglePageTouched(false));
    history.push(nextRoute);
  };

  const handleToggleModal = () => setState({ ...state, openModal: !state.openModal });
  const handleToggleModal2 = () => setState({ ...state, openModal2: !state.openModal2 });
  const handleToggleModal3 = () => setState({ ...state, openModal3: !state.openModal3 });

  const handleLogout = () => {
    queryClient.removeQueries();
    cachedState.clearState("filtersData");
    history.push("/signin");
    dispatch(logout());
  };

  return (
    <>
      <nav className={clsx("side-bar", { collapsed: !isSidebarOpen })}>
        {state.openModal3 && (
          <ImageUploadSidebar isOpen={state.openModal3} toggleOpen={handleToggleModal3} isLoading={setIsUploadingImg} />
        )}
        {state.openModal && (
          <ConfirmationModal
            isOpen={state.openModal}
            toggleOpen={handleToggleModal}
            action={handleConfirmLeave}
            color="primaryCta"
            title="Leave page"
            subtitle="Please note that all unsaved information will be lost"
            firstBtnTxt="Leave"
          />
        )}
        {state.openModal2 && (
          <ConfirmationModal
            isOpen={state.openModal2}
            toggleOpen={handleToggleModal2}
            action={handleLogout}
            color="primaryCta"
            title="Log out"
            subtitle="Please confirm logging out"
            firstBtnTxt="Log Out"
            secondBtnTxt="Stay"
          />
        )}

        <div className="sidebar-toggle">
          <Navigation24Filled primaryFill="#fff" onClick={handleToggleSidebar} />
        </div>
        <div className={clsx("user-panel", { "info-hidden": !isSidebarOpen })}>
          <div onClick={handleToggleModal3} className="user-avatar">
            <Avatar url={userInfo.facebook.photo} />
            {isUploadingImg && <div className="avatar-loading"></div>}
          </div>
          <div className="user-info">{userInfo.local.fullname}</div>
        </div>
        <div className="sidebar-nav">
          {sideBarItems.map((item, i) => (
            <SideBarItem
              key={i}
              text={item.text}
              route={item.route}
              Svg={item.Svg}
              dataTip={item.dataTip}
              handleSwitchRoute={handleSwitchRoute}
              showJennaLink={state.showJennaLink}
              isSidebarOpen={isSidebarOpen}
              pathName={history.location.pathname}
              toggleMoreSettings={toggleMoreSettings}
              isJennaLink={item.isJennaLink}
              isSettings={item.isSettings}
              isCandidates={item.isCandidates}
              isLogout={item.isLogout}
              newCount={newCount}
              userInfo={userInfo}
              toggleJennaLink={toggleJennaLink}
              functionForLogout={handleToggleModal2}
            />
          ))}
          <div
            className={clsx(
              "sidebar-more-settings",
              { "anim-open": state.isSettingsOpen },
              { "sidebar-open": !isSidebarOpen }
            )}
          >
            {settingsItems.map((item) => (
              <SettingsItem
                key={item.route}
                pathName={history.location.pathname}
                route={item.route}
                text={item.text}
                showJennaLink={state.showJennaLink}
                handleSwitchRoute={handleSwitchRoute}
              />
            ))}
          </div>
          <div
            onClick={handleToggleModal2}
            className="sidebar-nav-row"
            data-for="tooltip-sidebar"
            data-tip={!isSidebarOpen ? "Log Out" : null}
          >
            <div
              className={clsx("sidebar-nav-icon", {
                "row-collapsed": !isSidebarOpen,
              })}
            >
              <ConferenceRoom24Regular />
            </div>
            <div className="sidebar-nav-text">
              <span>Logout</span>
            </div>
          </div>
        </div>
      </nav>

      <YourJennaLink
        slideIn={state.showJennaLink ? "anim-slideIn" : false}
        slideOut={!state.showJennaLink ? "anim-slideOut" : false}
      />

      {state.showJennaLink ? (
        <div className="jenna-drawer">
          <div onClick={toggleJennaLink} className="jenna-backdrop"></div>
        </div>
      ) : null}
    </>
  );
};

export default SideBar;
