import { Children } from "react";
import axios from 'api/axios';
import {
  fetchNextStep,
  fetchNextStepNoRes,
  fetchReject,
  fetchRejectNoRes,
  fetchTwilioSms,
} from "../../api/candidatesAPI";

export const u_handleUpdateCandidate = (candidatesData: any, id: any, fields: any) => {
  var result = candidatesData?.candidates.map((c: any) => (c._id === id ? { ...c, ...fields } : c));

  return {
    ...candidatesData,
    candidates: result,
  };
};

export const u_handleRequestNextStep = (type: string, id: any, text: string, to: any, subject: any) => {
  switch (type) {
    case "mail":
      fetchNextStep({ candidateId: id, text, subject, type });
      break;
    case "sms":
      fetchNextStepNoRes({ candidateId: id, type });
      fetchTwilioSms({ candidateId: id, text, to });
      break;
    case "no":
      fetchNextStepNoRes({ candidateId: id, type });
      break;
    default:
      fetchNextStepNoRes({ candidateId: id, type });
      break;
  }
};

export const u_handleRequestReject = (type: string, id: any, text: string, to: any, subject: any) => {
  switch (type) {
    case "mail":
      fetchReject({ candidateId: id, text, subject, type });
      break;
    case "sms":
      fetchRejectNoRes({ candidateId: id, type });
      fetchTwilioSms({ candidateId: id, text, to });
      break;
    case "no":
      fetchRejectNoRes({ candidateId: id, type });
      break;

    default:
      fetchRejectNoRes({ candidateId: id, type });
      break;
  }
};

export const u_handleRequestUnreject =  (id: string) => {
  return new Promise((resolve, reject) => {
    axios.post<any>("/api/candidates/status", {
      cId: id,
      status: 'viewed',
    }).then(res => resolve(res))
    .catch(err => reject(err));
  })
};

export const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const flatten = (children: any, flat: any = []): any => {
  flat = [...flat, ...Children.toArray(children)];

  if (children.props && children.props.children) {
    return flatten(children.props.children, flat);
  }

  return flat;
};

export const simplify = (children: any) => {
  if (!children) return;
  const flat = flatten(children);

  return flat.map(({ key, ref, type, props: { children, ...props } }: any) => ({
    key,
    ref,
    type,
    props,
  }));
};
