import dayjs from "dayjs";

// Counts each individual question of provided type
// Used for NewPosStepThree to block user from selecting
// more than one email, phone, cv or linkedIn question.
export const countQuestions = (arr: any[], condition: string) => {
  let count = 0;
  for (var i = 0; i < arr.length; ++i) {
    if (arr[i].qType === condition) count++;
  }
  return count;
};

// Counts each individual question of provided type
// Used for NewPosStepThree to block user from selecting
// more than one email, phone, cv or linkedIn question.
export const findQuickReplies = (arr: any[]) => {
  let hasAny = false;
  for (var i = 0; i < arr.length; ++i) {
    if (arr[i].filter === true) hasAny = true;
  }
  return hasAny;
};

// Transforms values of React Select
// From: { value: string, label: string }[]
// To: string[]
export const transformSelectValues = (arr: any[]) => {
  if (!arr) return [];
  const tempArr = [] as string[];
  arr?.forEach((value: { label: string; value: string }) => tempArr.push(value.value));
  return tempArr;
};

// Transforms values for React Select
// If you pass in firstItem it will put it on top
// From: string[]
// To: { value: string, label: string }[]
export const transformToSelectValues = (arr: any[] | undefined, firstItem?: { value: string; label: string }) => {
  const tempArr = [] as { value: string; label: string }[];
  firstItem && tempArr.push(firstItem);
  arr?.forEach((value: string) => tempArr.push({ value, label: value }));
  return tempArr;
};

// Transforms values for React Select
// If you pass in firstItem it will put it on top
// From: string[]
// To: { value: string, label: string }[]
export const positionsToSelectValues = (arr: any[], firstItem?: { value: string; label: string }) => {
  if (!arr) return;
  const tempArr = [] as { value: string; label: string }[];
  firstItem && tempArr.push(firstItem);
  arr?.forEach((value: any) => tempArr.push({ value: value._id, label: value.name }));
  return tempArr;
};

// Returns price for passed in plan
export const findPlanReturnPrice = (products: any[], name: string, duration: string) => {
  if (!products) return 0;

  const product = products.find((prod) => prod.name.toLowerCase().startsWith(name.toLowerCase()));
  if (!product || product.name === "Custom") return 0;
  const price = product.prices.find((p: any) => p.interval === duration);

  return price ? price.unit_amount / 100 : 0;
};

// Returns id for passed in plan
export const findPlanReturnId = (products: any[], name: string) => {
  if (!products) return 0;

  const product = products.find((prod) => prod.name.toLowerCase().startsWith(name.toLowerCase()));
  return product?.id;
};

// Transforms value like "Today" into dates for filtering candidates
export const formatDate = (value: string) => {
  switch (value) {
    case "Today":
      return {
        datefrom: dayjs().format("MMMM DD, YYYY"),
        dateto: dayjs().add(1, "day").format("MMMM DD, YYYY"),
      };
    case "Yesterday":
      return {
        datefrom: dayjs().subtract(1, "day").format("MMMM DD, YYYY"),
        dateto: dayjs().format("MMMM DD, YYYY"),
      };
    case "Last 7 Days":
      return {
        datefrom: dayjs().subtract(6, "day").format("MMMM DD, YYYY"),
        dateto: dayjs().format("MMMM DD, YYYY"),
      };
    case "Last 30 Days":
      return {
        datefrom: dayjs().subtract(29, "day").format("MMMM DD, YYYY"),
        dateto: dayjs().format("MMMM DD, YYYY"),
      };
    case "This Month":
      return {
        datefrom: dayjs().startOf("month").format("MMMM DD, YYYY"),
        dateto: dayjs().endOf("month").format("MMMM DD, YYYY"),
      };
    case "Last Month":
      return {
        datefrom: dayjs().subtract(1, "month").startOf("month").format("MMMM DD, YYYY"),
        dateto: dayjs().subtract(1, "month").endOf("month").format("MMMM DD, YYYY"),
      };
    default:
      return {
        datefrom: "January 1, 2017",
        dateto: dayjs().add(1, "day").format("MMMM DD, YYYY"),
      };
  }
};

// Formats Date object for Candidate Drawer
export const formatDateForCandidateDrawer = (date: string) => {
  const formatedDate = dayjs(date).format("DD MMM YYYY | h:mm a");
  return formatedDate;
};

// Formats Date object for Candidate Drawer
export const formatDateAndViaForCandidateDrawer = (date: string, source: string) => {
  const src = source === "jenna_web" ? "Jenna Web" : source === "jenna_bubble" ? "Jenna Bubble" : "Jenna Web";
  return `Applied Via ${src} on ${formatDateForCandidateDrawer(date)}`;
};

// Formats Personality insight beacuse for some reason it
// has 17 white spaces between words...
export const splitPersonalityTraits = (word: string = "") => {
  const first_word = word.split(" ")[0];
  const length = word.split(" ").length;
  const second_word = word.split(" ")[length - 1];
  return { first_word, second_word };
};
