import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { selectIsAuthenticated } from "../../app/slices/userSlice";
import useHandlePremissions from "../../hooks/useHandlePremissions";
import RenderComponent from "../utils/RenderComponent";

interface Props {
  component: any;
  exact?: boolean;
  path: string;
  adminOnly?: boolean;
  planCanceled?: boolean;
}

const PrivateRoute: React.FC<PropsWithChildren<Props>> = ({
  component: Component,
  adminOnly,
  planCanceled,
  ...rest
}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  // Handles admin only and plan restricted routes
  const { shouldRedirect, path } = useHandlePremissions();

  if (shouldRedirect) {
    return <Redirect to={path} />;
  }

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <RenderComponent component={Component} props={props} /> : <Redirect to="/signin" />
      }
    />
  );
};

export default PrivateRoute;
