import axios from "axios";

// process.env.REACT_APP_API_URL
// http://localhost:8081

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("jennaToken");

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response?.data && error.response?.data?.location) {
      window.location.pathname = error.response?.data?.location;
    } else if (error.response?.data === "Unauthorized") {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("jennaToken");

      window.location.pathname = "/signin";
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
