import { sendNotification } from "../utils/functions/sendNotification";

const useCacheState = (key?: string, initialState?: any) => {
  const cacheState = (state: any, localKey?: string) => {
    if (!localKey && !key) return sendNotification("info", "No key provided.");
    localStorage.setItem(`stateCache_${localKey || key}`, JSON.stringify(state));
  };

  const retrieveState = (localKey?: string) => {
    if (!localKey && !key) return sendNotification("info", "No key provided.");
    const stateFromLS = JSON.parse(localStorage.getItem(`stateCache_${localKey || key}`)!);
    return stateFromLS ? stateFromLS : initialState || null;
  };

  const clearState = (localKey?: string) => {
    if (!localKey && !key) return sendNotification("info", "No key provided.");
    localStorage.removeItem(`stateCache_${localKey || key}`);
  };

  const updateState = (data: any, localKey?: string) => {
    if (!localKey && !key) return sendNotification("info", "No key provided.");
    const stateFromLS = JSON.parse(localStorage.getItem(`stateCache_${localKey || key}`)!);
    const update = typeof stateFromLS === "object" ? { ...stateFromLS, ...data } : data;
    localStorage.setItem(`stateCache_${localKey || key}`, JSON.stringify(update));
  };

  return { cacheState, retrieveState, clearState, updateState };
};

export default useCacheState;
