import React, { Suspense } from "react";
import Header from "../../Components/shared/Header/Header";
import SideBar from "../../Components/shared/SideBar/SideBar";
import SuspenseSpinner from "../../Components/SuspenseSpinner/SuspenseSpinner";
import JennaTransition from "../../Components/shared/JennaTransition/JennaTransition";
import GlobalErrorBoundary from "../../Components/ErrorBoundary/GlobalErrorBoundary";
import JennaSideLogo from "../../Components/shared/JennaSideLogo/JennaSideLogo";

interface Props {
  component: any;
  props: any;
}

const routesWithJennaLogo = ["/register", "/signin", "/forgot", "/reset", "/becomeMember"];

const RenderComponent: React.FC<Props> = ({ component: Component, props }) => {
  return (
    <>
      <Header />
      <SideBar />
      <Suspense fallback={<SuspenseSpinner />}>
        <GlobalErrorBoundary>
          <JennaTransition>
            <Component {...props} />
          </JennaTransition>
        </GlobalErrorBoundary>
      </Suspense>
    </>
  );
};

const RenderRawComponent: React.FC<Props> = ({ component: Component, props }) => {
  return (
    <>
      {routesWithJennaLogo.some((route) => props.location.pathname.includes(route)) && <JennaSideLogo />}
      <Suspense fallback={<SuspenseSpinner />}>
        <GlobalErrorBoundary>
          <JennaTransition>
            <Component {...props} />
          </JennaTransition>
        </GlobalErrorBoundary>
      </Suspense>
    </>
  );
};

export default RenderComponent;
export { RenderRawComponent };
