import "./Header.scss";
import { ReactComponent as WebChat } from "../../../assets/Web_Chat.svg";
import { ReactComponent as JennaLogo } from "../../../assets/jenna_logo.svg";
import { ReactComponent as JemnaLogo } from "../../../assets/jennaLogos/jemnaAI.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsSidebarOpen } from "../../../app/slices/utilsSlice";
import { selectUserBot } from "../../../app/slices/userSlice";

const Header = () => {
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const bot = useSelector(selectUserBot);

  return (
    <header className="header">
      <Link className="logo-link" to="/">
        <JennaLogo className={isSidebarOpen ? "sidebar-open" : undefined} />
      </Link>
      <a
        href={`${process.env.REACT_APP_WEBCHAT_URL}/${bot?._id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="web-chat">
          <WebChat />
          <span>Web Chat</span>
        </div>
      </a>
    </header>
  );
};

export default Header;
