import { Switch } from "react-router-dom";
import useGetNoOfNew from "../../hooks/useGetNoOfNew";
import useHandleFirstTime from "../../hooks/useHandleFirstTime";
import { allRoutes } from "./routesConfig";

const AllRoutes = () => {
  useGetNoOfNew();
  useHandleFirstTime();

  return (
    <Switch>
      {allRoutes.map((route, i) => (
        // If you put in the key the transition will bug out
        <route.RouteComponent exact={route.exact} path={route.path} component={route.component} />
      ))}
    </Switch>
  );
};

export default AllRoutes;
