import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import utilsReducer from "./slices/utilsSlice";
import candidateReducer from "./slices/candidateSlice";
import phoneReducer from './slices/phone';

export default configureStore({
  reducer: {
    user: userReducer,
    candidate: candidateReducer,
    utils: utilsReducer,
    phone: phoneReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
