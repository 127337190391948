import AllRoutes from "./config/AllRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import useSocketEvents from "../hooks/useSocketEvents";
import useSocket from "../hooks/useSocket";
import { useEffect } from "react";

const Routes = () => {
  useSocketEvents();
  const { initSocket, joinRoom, socket } = useSocket();

  useEffect(() => {
    initSocket();
  }, []);

  useEffect(() => {
    setTimeout(() => joinRoom(), 2000);
  }, [socket, joinRoom]);

  return (
    <Router>
      <AllRoutes />
    </Router>
  );
};

export default Routes;
