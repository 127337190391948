import { createSlice } from "@reduxjs/toolkit";

export interface PhoneInfo {
  isValid: boolean;
  region: string;
  country: string;
  e164: string;
  significant: string;
}

export const candidateSlice = createSlice({
  name: "candidate",
  initialState: {
    candidateCall: null,
    newCount: 0,
    newCandidate: null
  } as any,
  reducers: {
    startCall: (state, action) => {
      state.candidateCall = action.payload.candidateInfo;
    },
    stopCall: (state) => {
      state.candidateCall = null;
    },
    "(✿◠‿◠) count": (state, action) => {
      state.newCount = action.payload.count;
    },
    decrementCount: (state) => {
      state.newCount -= 1;
    }, 
    incrementCount: (state) => {
      state.newCount += 1;
    },
    newCandidateAlert: (state, action) => {
      state.newCandidate = action.payload.candidateInfo;
    },
  },
});

export const { startCall, stopCall, decrementCount, incrementCount, newCandidateAlert } = candidateSlice.actions;
export const updateCount = candidateSlice.actions["(✿◠‿◠) count"];

export const selectCallInfo = (state: any) => state.candidate.candidateCall;
export const selectNewCandidates = (state: any) => state.candidate.newCount;
export const selectNewCandidateInfo = (state: any) => state.candidate.newCandidate;
export const selectPhoneInfo = (state: any): PhoneInfo => state.candidate.candidateCall?.phone_info;

export default candidateSlice.reducer;
