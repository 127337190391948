import { createSlice } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";

interface InitialState {
  utils: {
    sidebarOpen: boolean;
    pageTouched: boolean;
    socket: Socket;
  };
}
if (localStorage.getItem("isSidebarOpen") === "open") localStorage.setItem("isSidebarOpen", "false");

export const utilsSlice = createSlice({
  name: "utils",
  initialState: {
    sidebarOpen: JSON.parse(localStorage.getItem("isSidebarOpen") || "false"),
    pageTouched: false,
    socket: null,
  },
  reducers: {
    "∩( ・ω・)∩ sidebar": (state) => {
      state.sidebarOpen = !state.sidebarOpen;
      state.sidebarOpen ? localStorage.setItem("isSidebarOpen", "true") : localStorage.removeItem("isSidebarOpen");
    },
    "(ﾉ◕ヮ◕)ﾉ✧ touched": (state, action) => {
      state.pageTouched = action.payload;
    },
    "｡(✿‿✿)｡ socket": (state, action) => {
      state.socket = action.payload;
    },
  },
});

export const toggleSidebar = utilsSlice.actions["∩( ・ω・)∩ sidebar"];
export const togglePageTouched = utilsSlice.actions["(ﾉ◕ヮ◕)ﾉ✧ touched"];
export const addSocket = utilsSlice.actions["｡(✿‿✿)｡ socket"];

export const selectIsSidebarOpen = (state: InitialState) => {
  return state.utils?.sidebarOpen;
};
export const selectPageTouched = (state: InitialState) => state.utils.pageTouched;
export const selectSocket = (state: InitialState) => state.utils.socket;

export default utilsSlice.reducer;
